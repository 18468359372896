//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-140:_4496,_3036,_1744,_4988,_2480,_5568,_2069,_4460;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-140')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-140', "_4496,_3036,_1744,_4988,_2480,_5568,_2069,_4460");
        }
      }catch (ex) {
        console.error(ex);
      }